@import 'custom_bootstrap_vars';

//bootswatch color customization
$yellow: #e2872c;
$dropdown-link-color: #373737;

@import '~bootswatch/dist/flatly/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/lumen/bootswatch';
@import 'mapbox-gl/dist/mapbox-gl.css';

$bootstrap-icons-font-dir: '~bootstrap-icons/font/fonts';
@import '~bootstrap-icons/font/bootstrap-icons';

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.htmx-request {
  transition: all 500ms ease-in;
  opacity: 0.5;
}

@media (min-width: 768px) {
  .navbar-brand.abs {
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.form-label {
  font-weight: bold;
}

.asteriskField {
  color: $red;
  margin-left: 5px;
}

////////////
// Pace //
////////////
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $white;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

table.table thead th.orderable a {
  text-decoration: none;
  color: inherit;

  &::after {
    @extend .bi;
    padding-left: 0.3em;
    content: '\F145';
    font-family: 'bootstrap-icons';
    vertical-align: -0.125em;
  }
}

table.table thead th.asc.orderable a::after {
  content: '\F125';
}

table.table thead th.desc.orderable a::after {
  content: '\F146';
}
